import React from 'react';
import Logo from '../UI/Logo';
import Copyright from '../UI/Copyright';
import SocialIcons from '../UI/SocialIcons';

export default function Footer() {
  return (
    <footer className='text-gray-500 bg-gray-900 body-font'>
      <div className='container px-6 py-8 mx-auto flex items-center sm:flex-row flex-col'>
        <Logo />
        <Copyright
          name={'Connor Casner'}
          twitterUrl={'https://twitter.com/NyashaNziboi'}
        />
        <SocialIcons
          githubURL={'https://github.com/mywork-dragon'}
          linkedInURL={
            'https://www.linkedin.com/in/connordcasner/'
          }
        />
      </div>
    </footer>
  );
}
