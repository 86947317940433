import React, { Fragment, useState } from 'react';
import lowerWave from '../assets/icons/background/lower-wave-1.svg';
import upperWave from '../assets/icons/background/upper-wave-1.svg';
import Heading from '../UI/Heading';
import PortfolioCard from '../UI/PortfolioCard';
import ShineonPng from '../assets/images/portfolio/shienon.png';
import slamgatorPng from '../assets/images/portfolio/project-slamgator.png';
import weddingSpot from '../assets/images/portfolio/weddingspot.png';
import Prosapient from '../assets/images/portfolio/prosapient.png';
import Foodective from '../assets/images/portfolio/foodective.png';
import CignaPng from '../assets/images/portfolio/cigna_health_homepage.jpg';
import TaskDent from '../assets/images/portfolio/TaskDent.jpg';
import GoSchoolBox from '../assets/images/portfolio/goschoolbox.png';
import Buxfer from '../assets/images/portfolio/buxfer.png';
import Exigo from '../assets/images/portfolio/Exigo.png';
import Link from '../UI/Link';
import data from '../data/portfolio.json';

export default function Portfolio() {
  const [openTab, setOpenTab] = useState('web');

  return (
    <Fragment>
      <img src={upperWave} alt='' />
      <section className='text-gray-400 bg-gray-900 body-font'>
        <Heading h1={'PORTFOLIO'} h2={data.heading} animation={'fade-up'} />

        <div className='container px-5 py-12 mx-auto' data-aos='fade-in'>
          <br />

          {/* <PortfolioCard
            name={'proSapient'}
            desc={
              'proSapient is a company that provides primary research and expert networking services to institutional investors, consultants, and corporate clients globally. \n ❖ Led the development team in creating a global deployment for a product with a complex process, focusing on clear and easy-to-perceive visual solutions. \n ❖ Collaborated with the largest customers of the client to provide customized development and deployment services. \n ❖ Reviewed the software architecture and infrastructure to ensure a robust and reliable design.'
            }
            tech={'react, redux, next, typescript, css, tailwindcss, graphql'}
            githubURL={''}
            webURL={'https://goschoolbox.com/'}
            gif={''}
            image={Prosapient}
            platform={'web'}
            openTab={openTab}
          /> */}
          <PortfolioCard
            name={'Buxfer'}
            desc={
              "Online money management software for tracking expenses, budgets, bill reminders, investments, forecasting, financial planning & more.\n❖ Developed NextJS-based Finance websites using React, Typescript, NextJS, Python, Flask and Tailwind CSS.\n❖ Responsive web and mobile design using SASS and Foundation to allow for best user experience when using their online portal.\n❖ Implemented multiple graphs using Chart.js to let users manage their spendings toward their deductibles."
            }
            tech={'react, next, typescript, python, flask, tailwindcss, sass'}
            githubURL={''}
            webURL={'https://demo.buxfer.com/dashboard'}
            gif={''}
            image={Buxfer}
            platform={'web'}
            openTab={openTab}
          />

          <PortfolioCard
            name={'GoSchoolBox'}
            desc={
              'GoSchoolBox is a SaaS platform that serves as the technical infrastructure layer between school districts and tutoring companies. \n ❖ Implemented the front-end using React and Material-UI in Javascript, the back-end using MongoDB, Express and Typescript with GraphQL sending data between the two.\n❖ Refactored and fixed critical user flows to improve user flow performance, server throughput and page load performance. \n❖ Optimised Mongo search performance to allow users search millions of records within seconds.'
            }
            tech={'react, redux, node, chartjs, typescript, sass, materialui, graphql, mongodb, jest'}
            githubURL={''}
            webURL={'https://goschoolbox.com/'}
            gif={''}
            image={GoSchoolBox}
            platform={'web'}
            openTab={openTab}
          />
          <PortfolioCard
            name={'TaskDent'}
            desc={
              'An all-in-one dental practice management platform with a user-friendly interface and an advanced monitoring dashboard, offering accurate and timely tracking of all the metrics of a dental business. \n ❖ Implement a secure, cloud-based dental practice management system, transitioning from an inefficient spreadsheet method. \n ❖ Developed an MVP utilizing Angular, React, PHP, and Laravel ensuring a user-friendly interface, real-time monitoring, and robust data management. \n ❖ Achieved secure, instant accessibility to sensitive data across various locations, earning commendation from TaskDent\’s founder.'
            }
            tech={'angular, react, typescript, php, laravel, postgres, redis, aws, elasticsearch'}
            githubURL={''}
            webURL={'https://www.taskdent.com/'}
            gif={''}
            image={TaskDent}
            platform={'web'}
            openTab={openTab}
          />

          <PortfolioCard
            name={'Exigo'}
            desc={
              'Exigo is a Platform-as-a-Service (PaaS) solution for direct selling companies. \n ❖ Develop a robust, all-in-one CRM system to enable a seamlessly operating, end-to-end direct-selling ecosystem, while also ensuring improved tracking and cost efficiency.\n ❖ Addressed and resolved unforeseen issues during the delivery and operational support phase, utilizing agile methodologies to implement effective solutions and ensure the project stayed on track.'
            }
            tech={'python, django, angular, javascript, typescript, mysql, docker, kubernetes, aws'}
            githubURL={''}
            webURL={'https://www.exigo.com/'}
            gif={''}
            image={Exigo}
            platform={'web'}
            openTab={openTab}
          />


          <PortfolioCard
            name={'Shineon'}
            desc={
              "ShineOn is an e-commerce platform that facilitates the creation and sale of high-quality jewelry with a cash-efficient, on-demand supply chain for digital marketers.\n❖ Developed and maintained cutting-edge web-based applications using PHP/Laravel on portal plus premium service platforms, utilizing skills in PHP, Laravel, Composer, Git, Gulp, Vagrant, and PHPUnit.\n❖ Collaborated with the User Experience (UX) team to build innovative and state-of-the-art applications that deliver exceptional user experiences, utilizing skills in Vue, Vuex, and TypeScript."
            }
            tech={'vue, typescript, php, laravel, aws, docker, tailwindcss, azure'}
            githubURL={''}
            webURL={'https://shineon.com/'}
            gif={''}
            image={ShineonPng}
            platform={'web'}
            openTab={openTab}
          />
          <PortfolioCard
            name={'Foodective'}
            desc={
              'Foodetective is the management platform & infrastructure (API) of the merchant industry.\n ❖ Designed and developed a customer-friendly application with a strong database of proven food businesses using React Native and TypeScript for mobile app development.\n ❖ Integrated a large amount of third-party tools within the application, including bookings, delivery, takeaway orders, mobile payments, queue management, and catering companies.\n ❖ Built a proprietary metasearch engine capable of making highly personalized recommendations using solution architecture techniques.'
            }
            tech={'reactnative, typescript, materialui, firebase, xcode'}
            githubURL={''}
            webURL={'https://apps.apple.com/ch/app/foodetective-find-restaurants/id1411915101'}
            gif={''}
            image={Foodective}
            platform={'web'}
            openTab={openTab}
          />

          <PortfolioCard
            name={'Wedding Spot'}
            desc={
              "Wedding Spot is the first online site that allows you to search, price out, and compare wedding venues.\n❖ Developed a new function with high monthly user engagement for a wedding restaurant ordering website.\n❖ Managed various tasks including speed optimization, localization, and database administration.\n❖ Deployed MVP and continued to upgrade the site regularly, gaining experience in CI/CD.\n❖ Utilized Rails and various gems such as Devise, Paperclip, and Ransack to implement key features."
            }
            tech={'ruby, rails, react, typescript, postgres, tailwindcss, docker'}
            githubURL={''}
            webURL={'https://www.wedding-spot.com'}
            gif={''}
            image={weddingSpot}
            platform={'web'}
            openTab={openTab}
          />
          <Link
            href={'https://github.com/mywork-dragon'}
            text={'More on Github...'}
          />
        </div>
      </section>
      <img src={lowerWave} alt='' />
    </Fragment>
  );
}
